@tailwind base;
@tailwind components;
@tailwind utilities;



/* Hide scrollbar in webkit browsers */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  
  /* Firefox */
  .scrollbar-hidden {
    scrollbar-width: none;
  }

  .scroll-animation {
    display: flex;
    animation: scroll 20s linear infinite; 
   
  }
  
  .scroll-animation:hover {
    animation-play-state: paused; /* Pause animation on hover */
  }
  
  /* Faster scrolling for mobile devices */
  @media (max-width: 768px) {
    .scroll-animation {
      animation: scroll 10s linear infinite;
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-200%);
      }
    }
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }